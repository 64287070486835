:root {
    --background: #F7F7FA;
    --background-sidebar: #EFEFF5;
    --text: #91939F;
    --primary: #2757FF;
    --secondary: #95BC48;
    --title: #1D253C;
    --shape: #fff;
}

*{
    margin: 0px;
    padding: 0px;
    outline: 0px;
    box-sizing: border-box;
} 

body{
    background: var(--background);
    color: var(--title);
}

body, input, textarea, label, button{
    font-family: 'Montserrat', sans-serif;
}

button{
    cursor: pointer;
}

h1, h2, h3, h4, h5, h6{
    font-weight: 900;
}